
import { Options, Prop, Watch } from "vue-property-decorator";
import EducationTabs from "@/components/EducationTabs/EducationTabs.vue";
import AccountImg from "@/components/Pages/Home/Account/AccountImg.vue";
import { mixins } from "vue-class-component";
import StudentAccountEducationMixin from "@/mixins/StudentEducations/StudentAccountEducationMixin";
import ModalMixin from "@/mixins/ModalMixin";
import { namespace } from "s-vuex-class";
import { useIonRouter } from "@ionic/vue";
import { LivePayPaymentStatusEnum } from "@/enums/LivePayPaymentStatusEnum";
import { BalanceRequestMixin } from "@/mixins/Request/BalanceRequestMixin";

const BalanceModule = namespace("balance");
const LivePayMandateModule = namespace("live-pay-mandate");
const BroadCastModule = namespace("broadcast");

@Options({
  components: { AccountImg, EducationTabs },
})
export default class AccountBlock extends mixins(StudentAccountEducationMixin, ModalMixin, BalanceRequestMixin) {
  public name = "AccountBlock";

  private ionRouter = useIonRouter();

  @Prop({ type: Array, default: () => [] })
  public studentEducations!: Array<any>;

  @Prop({ default: () => false })
  public disabled!: boolean;

  @Prop({ default: () => false })
  public basicView!: boolean;

  @BalanceModule.Action("setPreview")
  public setPreviewAction: any;

  // false today, true all
  @BalanceModule.Getter("isPreview")
  public preview: any;

  @LivePayMandateModule.Action("findOne")
  public isLivePayMandateSetUpAction: any;

  @LivePayMandateModule.Getter("getIsLoading")
  public loadingLivePayMandateSetUp: any;

  @LivePayMandateModule.Getter("getDataItem")
  public isLivePayMandateSetUp: any;

  @LivePayMandateModule.Mutation("SET_DATA_ITEM")
  public livePayMandateSetUpReset: any;

  @BroadCastModule.Action("simplePost")
  public findLivePayPaymentStatus: any;

  @BroadCastModule.Getter("getDataItem")
  public livePayPaymentStatuses: any;

  public selectedEducationIndex = 0;

  public get localStorageGetPreview(): any {
    return sessionStorage.getItem("preview");
  }

  get livePayStudentEducations(): Array<any> {
    return this.studentEducations.filter((se: any) => se.activeLivePayMandate && se.activeLivePayMandateId);
  }

  private get blockBgColorClass(): string {
    if (this.selectedEducation?.costBearer) {
      return "bg-color-lime-900";
    } else if (this.livePayPaymentProcessing) {
      return "bg-color-yellow-orange-500";
    } else if (this.isLivePayEnabled(this.selectedEducation) && this.isLivePayMandateSetUp !== true) {
      return "bg-color-lime-900";
    } else {
      return this.balance >= 0 ? "bg-color-lime-900" : "bg-monza-500";
    }
  }

  private get educationTabsBgColorClass(): string {
    if (this.selectedEducation?.costBearer) {
      return "lime-900";
    } else if (this.livePayPaymentProcessing) {
      return "yellow-orange-500";
    } else if (this.isLivePayEnabled(this.selectedEducation) && this.isLivePayMandateSetUp !== true) {
      return "lime-900";
    } else {
      return this.balance >= 0 ? "lime-900" : "monza";
    }
  }

  private isLivePaySetUp(studentEducation: any): void {
    if (!studentEducation || !studentEducation.id || studentEducation?.mainLicenseClass === "Alle") {
      return;
    }
    this.isLivePayMandateSetUpAction({
      resource: "live-pay-mandate/by-student-education",
      id: `${studentEducation.id}/is-set-up`,
      hideSuccessMessage: true,
    });
  }

  // TODO remember preview/student education state
  private onEducationClassChanged(selectedEducation: any): void {
    this.selectEducation(selectedEducation);
    if (this.isLivePayEnabled(selectedEducation)) {
      this.isLivePaySetUp(selectedEducation);
    } else {
      this.resetLivePaySetup();
    }
  }

  public resetLivePaySetup() {
    this.livePayMandateSetUpReset(null);
  }

  private get previewState() {
    return JSON.parse(this.localStorageGetPreview) !== null ? JSON.parse(this.localStorageGetPreview) : false;
  }

  private set previewState(preview: boolean) {
    sessionStorage.setItem("preview", JSON.stringify(preview));
    this.setPreviewAction(preview);
    this.getBalances();
  }

  public onShowLoadAccountForm(): void {
    this.$router.push({ name: "TopUpAccount" });
  }

  public onClickAccountCard(): void {
    if ("Account" !== this.$route.name) {
      this.ionRouter.navigate("/account", "forward", "push");
    }
  }

  public get selectedStudentEducationRelatedToCostBearer(): boolean {
    return Boolean(this.selectedEducation?.costBearer);
  }

  // check live pay is in progress
  public get livePayPaymentProcessing() {
    const selectedStudentEducationId = this.selectedEducation?.id;

    if (selectedStudentEducationId) {
      return this.livePayPaymentStatuses?.[selectedStudentEducationId] === LivePayPaymentStatusEnum.YELLOW;
    }

    return false;
  }

  protected setSelectedEducationIndex() {
    const selectedEducationId = this.selectedEducation?.id;
    if (selectedEducationId && this.studentEducations?.length) {
      const index = this.studentEducations.findIndex((se: any) => se.id == selectedEducationId);

      if (index != -1 && index != 0) {
        this.selectedEducation = index;
      }
    }
  }

  @Watch("studentEducations", { deep: true })
  public onChangeStudentEducations(studentEducations: Array<any>) {
    if (studentEducations && studentEducations.length > 0) {
      // on mounted check has prev selected education
      this.setSelectedEducationIndex();

      const data = studentEducations.map((education: any) => education.id);

      this.findLivePayPaymentStatus({
        resource: "payment-workflows/live-pay-payment-status",
        data: data,
        hideSuccessMessage: true,
      });
    }
  }

  private get formatStudentEducations() {
    return [
      {
        id: new Date().valueOf(),
        mainLicenseClass: this.$t("todo.all").toString(),
      },
      ...this.studentEducations,
    ];
  }

  private get balance() {
    const licenseClass = this.selectedEducation.mainLicenseClass === "Alle" ? null : this.selectedEducation.mainLicenseClass;
    const bal = this.balances.find((b: any) => b.licenseClass == licenseClass);
    return (this.preview ? bal?.previewBalance : bal?.actualBalance) || 0;
  }

  public mounted() {
    this.getBalances();
  }
}

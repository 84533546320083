
import { Vue, Options } from "vue-property-decorator";
//@ts-ignore
import VueMultiselect from "vue-multiselect";

@Options({
  components: {
    VueMultiselect,
  },
})
export default class FscMultiselect extends Vue {
  public name = "FscMultiselect";
}

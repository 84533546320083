
import { Options, Vue } from "vue-property-decorator";
import EducationTabs from "@/components/EducationTabs/EducationTabs.vue";
import LoadAccountBtn from "@/components/Pages/Home/Account/LoadAccount/LoadAccountBtn.vue";
import Validation from "@/components/Validation.vue";
import FaceConfused from "@/components/Pages/Home/Account/LoadAccount/Emojies/FaceConfused.vue";
import FaceSmileWink from "@/components/Pages/Home/Account/LoadAccount/Emojies/FaceSmileWink.vue";
import { namespace } from "s-vuex-class";

const ThirdPartyPaymentModule = namespace("third-party-payment");

@Options({
  components: {
    FaceSmileWink,
    FaceConfused,
    LoadAccountBtn,
    EducationTabs,
    Validation,
  },
})
export default class AccountLoaded extends Vue {
  @ThirdPartyPaymentModule.Getter("getAmountInEur")
  private amountInEur: any;

  private onLoadAgainClick(): void {
    this.$emit("onLoadAgain");
  }

  private onCollectMoneyClick(): void {
    this.$emit("onCollectMoney");
  }
}

import { Options, Vue } from "vue-property-decorator";
import { namespace } from "s-vuex-class";
import { isLivePayEnabled } from "@/helper/LivePayHelper";

const StudentEducationModule = namespace("student-education");

@Options({})
export default class StudentEducationMixin extends Vue {
  @StudentEducationModule.Action("selectAccountStudentEducation")
  public selectAccountStudentEducation: any;

  @StudentEducationModule.Getter("getSelectedAccountStudentEducation")
  public selectedEducation: any;

  protected selectEducation(education: any): void {
    this.selectAccountStudentEducation(education);
  }

  protected isLivePayEnabled(selectedEducation: any): boolean {
    return isLivePayEnabled(selectedEducation);
  }
}

import { Options, Vue } from "vue-property-decorator";
import axios from "@/utils/axios";
import { AxiosError, AxiosResponse } from "axios";

@Options({})
export class BalanceRequestMixin extends Vue {
  public balances: any = [];
  public balancesLoading = false;
  public balancesSuccess = false;
  public balancesError = null;

  public async getBalances() {
    this.balancesLoading = true;
    this.balancesError = null;
    this.balancesSuccess = false;
    await axios
      .get("/balance/current-student")
      .then((response: AxiosResponse) => {
        this.balances = response.data;
        this.balancesSuccess = true;
      })
      .catch((err: AxiosError) => {
        this.balancesError = err.response?.data;
      })
      .finally(() => {
        this.balancesLoading = false;
      });
  }
}

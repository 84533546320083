import { Options, Vue } from "vue-property-decorator";
import FscModal from "@/components/FscModal.vue";

@Options({})
export default class ModalMixin extends Vue {
  protected showModal(event: any | null, modalName: string): void {
    if (this.$refs[modalName]) {
      (this.$refs[modalName] as FscModal).show();
      return;
    }
  }
  protected hideModal(event: any | null, modalName: string): void {
    if (this.$refs[modalName]) {
      (this.$refs[modalName] as FscModal).hide();
      return;
    }
  }
}
